import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const DashboardLayout = lazy(() => import('@/layouts/DashboardLayout'));
const MainLayout = lazy(() => import('@/layouts/MainLayout'));
const SignLayout = lazy(() => import('@/layouts/SignLayout'));

const UploadCenter = lazy(() => import('@/pages/UploadCenter')); // 上传中心
const Home = lazy(() => import('@/pages/Home')); // 运营概览
/* const MaterialManagement = lazy(() => import('@/pages/MaterialManagement')); // 素材管理
const VideoMaterial = lazy(() => import('@/pages/MaterialManagement/VideosMaterial')); // 视频
const ImagesMaterial = lazy(() => import('@/pages/MaterialManagement/ImagesMaterial')); // 图片
const SoundsMaterial = lazy(() => import('@/pages/MaterialManagement/SoundsMaterial')); // 音效
const SubtitlesMaterial = lazy(() => import('@/pages/MaterialManagement/SubtitlesMaterial')); // 字幕
const FontsMaterial = lazy(() => import('@/pages/MaterialManagement/FontsMaterial')); // 字体
const BGMMaterial = lazy(() => import('@/pages/MaterialManagement/BGMMaterial')); // BGM
const BackupSpace = lazy(() => import('@/pages/MaterialManagement/BackupSpace')); // 备份空间 */

const ContentManagement = lazy(() => import('@/pages/ContentManagement')); // 内容管理
const ContentDetails = lazy(() => import('@/pages/ContentManagement/ContentDetails')); // 内容管理详情页
const PlatformManagement = lazy(() => import('@/pages/PlatformManagement')); // 平台管理
const PlatformDetails = lazy(() => import('@/pages/PlatformManagement/PlatformDetails')); // 平台管理详情页
const AISubtitle = lazy(() => import('@/pages/AISubtitle')); // AI字幕
const SRTTranslation = lazy(() => import('@/pages/AISubtitle/SRTTranslation')); // SRT翻译
const TranslationDownload = lazy(() => import('@/pages/AISubtitle/TranslationDownload')); // 翻译下载

const DataAnaLysis = lazy(() => import('@/pages/DataAnaLysis')); // 数据分析
const CommercialMonetization = lazy(() => import('@/pages/CommercialMonetization')); // 商务变现
const Wallet = lazy(() => import('@/pages/Wallet')); // 硬币钱包
const Point = lazy(() => import('@/pages/Wallet/Point')); // point
const EarningManagement = lazy(() => import('@/pages/Wallet/EarningManagement')); // 收益管理
const AccountInfo = lazy(() => import('@/pages/Wallet/EarningManagement/AccountInfo')); // 银行信息
const PlatformIncomes = lazy(() => import('@/pages/Wallet/EarningManagement/PlatformIncomes')); // 平台收益

const RepostManagement = lazy(() => import('@/pages/RepostManagement')); // 搬运管理
const CreativeLaboratory = lazy(() => import('@/pages/CreativeLaboratory')); // 创作实验室

const Setting = lazy(() => import('@/pages/Setting')); // 设置
const UserInfo = lazy(() => import('@/pages/Setting/UserInfo')); // 用户信息
const AccountSecurity = lazy(() => import('@/pages/Setting/AccountSecurity')); // 账号安全
const Notification = lazy(() => import('@/pages/Setting/Notification')); // 通知
const Protocol = lazy(() => import('@/pages/Setting/Protocol')); // 协议
const Invitation = lazy(() => import('@/pages/Setting/Invitation')); // 邀请

const Feedback = lazy(() => import('@/pages/Feedback')); // 反馈
const PersonalSettings = lazy(() => import('@/pages/PersonalSettings')); // 个人设置
const AuthenticationCenter = lazy(() => import('@/pages/AuthenticationCenter')); // 认证中心

const NotFound = lazy(() => import('@/pages/NotFound'));
const SignIn = lazy(() => import('@/pages/SignIn'));
const SignUp = lazy(() => import('@/pages/SignUp'));
const Password = lazy(() => import('@/pages/Password'));
const GlobalPass = lazy(() => import('@/pages/AuthenticationCenter/components/CertificateModal'));
const GlobalPassFailed = lazy(
  () => import('@/pages/AuthenticationCenter/components/CertificateFailed')
);
const PlatformOperation = lazy(() => import('@/pages/PlatformOperation'));
const SelectPlatform = lazy(() => import('@/pages/PlatformOperation/Platform'));
const BindAccount = lazy(() => import('@/pages/PlatformOperation/Platform/BindAccount'));
const RegisterAccount = lazy(() => import('@/pages/PlatformOperation/Platform/RegisterAccount'));
const VerifyAccount = lazy(() => import('@/pages/PlatformOperation/Platform/VerifyAccount'));
const WaitingModal = lazy(() => import('@/pages/PlatformOperation/components/WaitingModal'));
const PlatformIntro = lazy(() => import('@/pages/PlatformOperation/Platform/PlatformIntro'));
const AddGlobalPass = lazy(() => import('@/pages/PlatformOperation/Platform/AddGlobalPass'));
const DeviceReminder = lazy(() => import('@/pages/DeviceReminder')); // 请使用PC端浏览器
const HelpCenter = lazy(() => import('@/pages/HelpCenter')); //客服中心详情
const UserAgreement = lazy(() => import('@/pages/Protocols/UserAgreement'));

const routes = [
  {
    path: '/v2',
    element: <DashboardLayout />,
    children: [
      { path: '/v2', element: <Navigate to="/v2/home" /> },
      { path: 'upload-center', element: <UploadCenter /> }, // 上传中心
      { path: 'home', element: <Home /> }, // 运营概览
      // 素材管理
      /* {
        path: 'material-management',
        element: <MaterialManagement />,
        children: [
          {
            path: '/v2/material-management',
            element: <Navigate to="/v2/material-management/video-material" />,
          },
          { path: 'video-material', element: <VideoMaterial /> },
          { path: 'images-material', element: <ImagesMaterial /> },
          { path: 'sounds-material', element: <SoundsMaterial /> },
          { path: 'subtitles-material', element: <SubtitlesMaterial /> },
          { path: 'fonts-material', element: <FontsMaterial /> },
          { path: 'bgm-material', element: <BGMMaterial /> },
          { path: 'backup-space', element: <BackupSpace /> },
        ],
      }, */
      // 内容管理
      {
        path: 'content-management',
        children: [
          { path: '', element: <ContentManagement /> },
          { path: 'content-details', element: <ContentDetails /> },
        ],
      },
      // 平台管理
      {
        path: 'platform-management',
        children: [
          { path: '', element: <PlatformManagement /> },
          { path: 'platform-details', element: <PlatformDetails /> },
        ],
      },
      // 字幕管理 // todo: 发布前确认是否上线该内容
      {
        path: 'ai-subtitle',
        element: <AISubtitle />,
        children: [
          {
            path: '/v2/ai-subtitle',
            element: <Navigate to="/v2/ai-subtitle/srt-translation" />,
          },
          { path: 'srt-translation', element: <SRTTranslation /> }, // SRT翻译
          { path: 'translation-download', element: <TranslationDownload /> }, // 翻译下载
        ],
      },
      { path: 'data-anaLysis', element: <DataAnaLysis /> }, // 数据分析
      { path: 'commercial-monetization', element: <CommercialMonetization /> }, // 商务变现

      // 硬币钱包
      {
        path: 'wallet',
        element: <Wallet />,
        children: [
          { path: '/v2/wallet', element: <Navigate to="/v2/wallet/a-point" /> },
          { path: 'a-point', element: <Point /> },
          {
            path: 'earning-management', // 收益管理
            children: [
              { path: '', element: <EarningManagement /> },
              { path: 'account-info', element: <AccountInfo /> },
              { path: 'platform-incomes', element: <PlatformIncomes /> },
            ],
          },
        ],
      },

      { path: 'repost-management', element: <RepostManagement /> }, // 搬运管理
      { path: 'creative-laboratory', element: <CreativeLaboratory /> }, // 创作实验室
      {
        path: 'setting',
        element: <Setting />,
        children: [
          {
            path: '/v2/setting',
            element: <Navigate to="/v2/setting/user-info" />,
          },
          { path: 'user-info', element: <UserInfo /> },
          { path: 'account-security', element: <AccountSecurity /> },
          { path: 'notification', element: <Notification /> },
          { path: 'protocol', element: <Protocol /> },
          { path: 'invitation', element: <Invitation /> },
        ],
      }, // 设置
      { path: 'feedback', element: <Feedback /> }, // 反馈
      { path: 'personal-settings', element: <PersonalSettings /> }, // 个人设置
      { path: 'authentication-center', element: <AuthenticationCenter /> }, // 认证中心
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/v2/home" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'device-reminder', element: <DeviceReminder /> },
      { path: 'help-center', element: <HelpCenter /> }, //客服中心
      { path: 'user-agreement', element: <UserAgreement /> },
      { path: 'global-pass', element: <GlobalPass /> },
      { path: 'global-pass-failed', element: <GlobalPassFailed /> },
      {
        path: 'platform-operation',
        children: [
          { path: '', element: <PlatformOperation /> },
          {
            path: 'platform',
            children: [
              { path: '', element: <SelectPlatform /> },
              { path: 'bind', element: <BindAccount /> },
              { path: 'register', element: <RegisterAccount /> },
              { path: 'verify', element: <VerifyAccount /> },
              { path: 'waiting', element: <WaitingModal /> },
              { path: 'platform-intro', element: <PlatformIntro /> },
              { path: 'global-pass', element: <AddGlobalPass /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '',
    element: <MainLayout />,
    children: [{ path: '', element: <Navigate to="/v2/home" /> }],
  },
  {
    path: '',
    element: <SignLayout />,
    children: [
      { path: 'signin', element: <SignIn /> },
      { path: 'signup', element: <SignUp /> },
      { path: 'password', element: <Password /> },
    ],
  },
];

export default routes;
