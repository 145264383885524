import { theme } from 'antd';
import type { MappingAlgorithm } from 'antd/es/config-provider/context';
import styles from './styles';
import components from './components';

// 定义 road 暗色模式算法
export const roadDarkAlgorithm: MappingAlgorithm = (seedToken, mapToken) => {
  // 使用 antd 默认的暗色算法生成基础token，这样其他不需要定制的部分则保持原样
  const baseToken = theme.darkAlgorithm(seedToken, mapToken);

  return {
    ...baseToken,
    colorPrimary: '#C2313A', // 主题色
    colorSuccess: '#00CC66', // 成功色
    colorError: '#FF0000', // 错误色
    // colorFail: '#FF3300', // 失败色
    colorWarning: '#FF9900', // 警告色
    colorLink: '#3399FF', // 链接色

    colorFail: '#FF3300', // 失败色
    colorFailBg: 'rgba(233, 68, 62, 0.05)', // 失败背景色

    colorBgLayout: '#2A2C2C', // Layout 背景色
    colorBgContainer: '#171717', // 组件容器背景色
    colorBgElevated: '#353535', // 悬浮容器背景色
  };
};

// 定义 studio 浅色模式算法
export const roadLightAlgorithm: MappingAlgorithm = (seedToken) => {
  // 使用 antd 默认的暗色算法生成基础token，这样其他不需要定制的部分则保持原样
  const baseToken = theme.defaultAlgorithm(seedToken);

  return {
    ...baseToken,
    colorPrimary: '#FF293B', // 主题色
    colorSuccess: '#00CC66', // 成功色
    colorError: '#FF0000', // 错误色
    // colorFail: '#FF3300', // 失败色
    colorWarning: '#FF9900', // 警告色
    colorLink: '#3399FF', // 链接色

    colorFail: '#FF3300', // 失败色
    colorFailBg: 'rgba(233, 68, 62, 0.05)', // 失败背景色

    colorBgLayout: '#F9F9F9', // Layout 背景色
    colorBgContainer: '#FFFFFF', // 组件容器背景色
    colorBgElevated: '#F7F7F9', // 悬浮容器背景色
  };
};

export const S = styles;
export const C = components;
