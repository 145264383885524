import { axios, axios2 } from '../utils/axios';
import {
  CheckExistType,
  SignInType,
  SignUpType,
  SignOffType,
  SendEmailType,
  ResetPasswordType,
  ResetEmailType,
  ResetPhoneType,
  BindBindGoogleOauthType,
  LoginInfoType,
  UserType,
  GetVerifyCodeType,
  UserCourierType,
  GlobalPassType,
  CreatorPassType,
  AuthHistoryType,
  CancelPassType,
  OverseaPlatformParams,
  OperatePlatformParams,
  PlatformCodeParams,
  OperatePlatformType,
  PlatformDataType,
  PlatformIncreaseParams,
  PlatformIncreaseType,
  GetNotificationsListParams,
  NotificationType,
  UpdateNotificationsParams,
  InvitationHistoryType,
  FreezeParams,
  ReplyStatusType,
  MemberType,
  InvitationType,
  MemberInviteStatusType,
  InvitationRecordType,
} from '@/stores/UserStore/types';

export interface ICheckExistResponse {
  isExist: boolean;
}

export interface ISignInResponse {
  token: {
    accessToken: string;
    refreshToken: string;
  };
  guideToOpening: boolean;
}

export interface IGoogleAuthStateResponse {
  state: string;
}

export interface ILoginInfoResponse {
  count: number;
  loginHistories: LoginInfoType[];
}

export interface IGetTrialTokenResponse {
  accessToken: string;
  refreshToken: string;
}

export interface ICreatorPassResponse {
  foreignPlatform: CreatorPassType;
}

export interface ICreateAuthHistoryParams {
  uid?: number;
  platformId: number;
  passportId?: number;
  use: string;
  authMode?: number;
  createAt?: string;
}

export interface IAuthHistoryResponse {
  count: number;
  authHistories: AuthHistoryType[];
}

export interface IGetOperatePlatformResponse {
  list: OperatePlatformType[];
}

export interface IPlatformDataResponse {
  byPlatform: PlatformDataType[];
  incr: PlatformDataType;
  sum: PlatformDataType;
}

export interface IPlatformIncreaseDataResponse {
  items: PlatformIncreaseType[];
}

export interface IGetNotificationsListResponse {
  count: number;
  list: NotificationType[];
}

export interface IGetInvitationHistoryResponse {
  count: number;
  list: InvitationHistoryType[];
}

export interface IReplyMemberInvitationParams {
  eid: number;
  status: ReplyStatusType;
}

export interface IGetInvitationRecordsParams {
  limit?: number;
  offset?: number;
  orders?: string[];
  uid?: number;
  eid?: number;
  inStatus?: MemberInviteStatusType[];
}

export interface IGetInvitationRecordsResponse {
  count: number;
  list: InvitationRecordType[];
}

export interface IGetUserBindEnterpriseInfoResponse {
  member: MemberType;
  invitation: InvitationType;
}

const userServices = {
  checkExist: (params: CheckExistType) =>
    axios2.get<ICheckExistResponse, ICheckExistResponse>('/user/v2/exist', { params }),
  signIn: (params: SignInType) =>
    axios2.post<ISignInResponse, ISignInResponse>('/user/v2/sign_in', params),
  googleAuthState: () =>
    axios2.get<IGoogleAuthStateResponse, IGoogleAuthStateResponse>('/user/v2/state'),
  sendEmailLink: (params: SendEmailType) => axios2.post('/user/v2/email_link', params),
  resetPassword: (params: ResetPasswordType) => axios2.patch('/user/v2/passwd', params),
  signUp: (params: SignUpType) =>
    axios2.post<ISignInResponse, ISignInResponse>('/user/v2/sign_up', params),
  signOff: (params: SignOffType) => axios.post('/user/v2/sign_off', params),
  // 获取验证码
  getVerifyCode: (params: GetVerifyCodeType) => axios.get('/user/v1/verify_code', { params }),
  // 验证手机号/邮箱
  checkVerifyCode: (params: GetVerifyCodeType) => axios.post('/user/v1/verify_code', params),
  // 获取用户信息
  getUser: () => axios.get<UserType, UserType>('/user/v2'),
  // 获取体验用户token
  getTrialToken: () =>
    axios.get<IGetTrialTokenResponse, IGetTrialTokenResponse>('/user/v2/trial_token'),
  // 重置邮箱
  resetEmail: (params: ResetEmailType) => axios.patch('/user/v2/email', params),
  // 重置手机号
  resetPhone: (params: ResetPhoneType) => axios.patch('/user/v2/phone', params),
  // 绑定google账号
  bindGoogle: (params: BindBindGoogleOauthType) => axios.put('/user/v2/bind_google', params),
  // 获取登录信息
  getSignInInfo: () =>
    axios.get<ILoginInfoResponse, ILoginInfoResponse>('/user/v2/login_histories'),
  // 移除登录信息
  removeSignInInfo: (id: number) => axios.delete(`/user/v2/login_history/${id}`),
  // 获取Courier设置 (邮件语言及通知状态)
  getCourier: () => axios.get<UserCourierType, UserCourierType>('/user/v2/courier'),
  // 更新Courier设置 (邮件语言及通知状态)
  updateCourier: (params: UserCourierType) => axios.patch('/user/v2/courier', params),

  // 获取Global Pass信息
  getGlobalPass: (isView?: boolean) =>
    axios.get<GlobalPassType, GlobalPassType>('/user/v2/passport', { params: { default: isView } }),
  // 提交Global Pass
  submitGlobalPass: (params: GlobalPassType) => axios.post('/user/v2/passport', params),
  // 获取Creator Pass信息
  getCreatorPass: () =>
    axios.get<ICreatorPassResponse, ICreatorPassResponse>('/user/v2/foreign_platform_ai'),
  // 提交Creator Pass
  submitCreatorPass: (params: CreatorPassType) => axios.put('/user/v2/foreign_platform', params),
  // 取消认证
  cancelAuthPass: (params: CancelPassType) => axios.put('/user/v2/cancel_auth', params),
  //创建认证历史
  createAuthHistory: (params: ICreateAuthHistoryParams) =>
    axios.post('/user/v2/auth_history', params),
  // 获取认证历史
  getAuthHistory: (params?: string) =>
    axios.get<IAuthHistoryResponse, IAuthHistoryResponse>('/user/v2/auth_histories', { params }),

  // 创建平台资料
  createOverseaPlatform: (params: OverseaPlatformParams) =>
    axios.post('/user/v2/foreign_platform', params),
  // 查询我运营中的平台列表
  getOperatePlatform: () =>
    axios.get<IGetOperatePlatformResponse, IGetOperatePlatformResponse>(
      '/user/v2/operating_platforms'
    ),
  // 冻结运营平台
  freezeOperatingPlatform: (params: FreezeParams) =>
    axios.patch('/user/v2/operating_platforms:unfreeze', params),

  getPlatformData: () =>
    axios.get<IPlatformDataResponse, IPlatformDataResponse>('/spiderman/v1/creators/totals/status'),
  getPlatformIncreaseData: (params: PlatformIncreaseParams) =>
    axios.get<IPlatformIncreaseDataResponse, IPlatformIncreaseDataResponse>(
      '/spiderman/v1/creators/totals/incr',
      {
        params,
      }
    ),
  // 创建运营平台
  createOperatePlatform: (params: OperatePlatformParams) =>
    axios.post('/user/v2/operating_platform', params),
  // 进入到提交验证码页面,准备输入验证码
  readyToGetCode: (params: PlatformCodeParams) =>
    axios.post('/user/v2/account/verify_code:online', params),
  // 发送/提交验证码 (不传code时为发送, 传code时为提交)
  submitPlatformCode: (params: PlatformCodeParams) =>
    axios.post('/user/v2/account/verify_code:send', params),
  getUserPlatformCode: (pid: number, sessionType: string) =>
    axios.get<PlatformCodeParams, PlatformCodeParams>(
      `/user/v2/account/verify_code?pid=${pid}&sessionType=${sessionType}`
    ),

  // 获取消息列表
  getNotificationsList: (params: GetNotificationsListParams) =>
    axios.get<IGetNotificationsListResponse, IGetNotificationsListResponse>(
      '/user/v2/courier/receiver_notifications',
      { params }
    ),
  // 更新消息状态(已读、删除) All message status can only be updated as "READ" or "DELETED" by receiver.
  updateNotifications: (params: UpdateNotificationsParams) =>
    axios.patch('/user/v2/courier/receiver_notifications', params),

  // 获取邀请码
  getInvitationCode: () =>
    axios.get<{ code: string }, { code: string }>('/user/v2/invitation/code'),
  getInvitationHistory: () =>
    axios.get<IGetInvitationHistoryResponse, IGetInvitationHistoryResponse>('/user/v2/invitations'),

  // 回复成员邀请
  replyMemberInvitation: (params: IReplyMemberInvitationParams) =>
    axios.post('/user/v2/member/invitation:reply', params),

  // 获取邀请记录表 http://18.163.16.71:8800/v2/user#tag/enterprise/operation/UserApi2_ListMemberInvitationWithRecords
  getInvitationRecords: (params: IGetInvitationRecordsParams) =>
    axios.get<IGetInvitationRecordsResponse, IGetInvitationRecordsResponse>(
      '/user/v2/member/invitation_records',
      { params }
    ),

  // 查询用户当前绑定的企业信息 http://18.163.16.71:8800/v2/user#tag/enterprise/operation/UserApi2_GetMemberWithInvitation
  getUserBindEnterpriseInfo: () =>
    axios.get<IGetUserBindEnterpriseInfoResponse, IGetUserBindEnterpriseInfoResponse>(
      '/user/v2/member/invitation'
    ),
};

export default userServices;
