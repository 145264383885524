import { axios } from '../utils/axios';
import {
  EarningUserLevel,
  AssignEarningType,
  BankListType,
  UserAccountType,
  WithdrawalType,
  WithdrawalTaxesType,
} from '../stores/EarningStore/types';

export interface IWalletBalanceResponse {
  amount: number;
  totalWithdrawal: number;
  withdrawable: number;
}

export interface IDuringEarningParams {
  unit: 'DAY' | 'MONTH';
  pid?: number;
  start: string;
  end: string;
}

export interface IDuringEarningResponse {
  count: number;
  dates: string[];
  fees: string[];
  levels: EarningUserLevel[];
  rates: string[];
  reals: string[];
  taxes: string[];
  totals: string[];
}

export interface IAssignEarningParams {
  unit: 'DAY' | 'MONTH';
  date: string;
}

export interface IAssignEarningResponse {
  count: number;
  sum: AssignEarningType;
  incomes: AssignEarningType[];
}

export interface IWalletBankResponse {
  banks: BankListType[];
}

export interface IWalletUpdateAccountParams {
  code: string;
  recipient: {
    paypal: string;
  };
}

export interface IWalletTaxesResponse {
  list: WithdrawalTaxesType[];
}

export interface IWalletWithdrawalListResponse {
  count: number;
  items: WithdrawalType[];
}

const earningServices = {
  getWalletBalance: () =>
    axios.get<IWalletBalanceResponse, IWalletBalanceResponse>('/wallet/v1/balance'),
  getDuringEarning: (params: IDuringEarningParams) =>
    axios.get<IDuringEarningResponse, IDuringEarningResponse>('/spiderman/v1/creators/incomes/ts', {
      params,
    }),
  getAssignEarning: (params: IAssignEarningParams) =>
    axios.get<IAssignEarningResponse, IAssignEarningResponse>('/spiderman/v1/creators/incomes', {
      params,
    }),
  getBankList: () => axios.get<IWalletBankResponse, IWalletBankResponse>('/wallet/v1/banks'),
  getUserAccount: () => axios.get<UserAccountType, UserAccountType>('/wallet/v1/recipient'),
  updateUserAccount: (params: IWalletUpdateAccountParams) =>
    axios.put('/wallet/v1/recipient', params),
  sendEmailCode: () => axios.get('/wallet/v1/verify_code'),

  getTaxes: () =>
    axios.get<IWalletTaxesResponse, IWalletTaxesResponse>('/studio/v1/wallet/tax_manage_info'),
  getWithdrawalList: (params: any) =>
    axios.get<IWalletWithdrawalListResponse, IWalletWithdrawalListResponse>(
      '/wallet/v1/withdrawals',
      { params }
    ),
  applyWithdrawal: () =>
    axios.post<WithdrawalType, WithdrawalType>('/wallet/v1/withdrawals:prepare', {}),
  confirmWithdrawal: (verificationCode: string) =>
    axios.post('/wallet/v1/withdrawals:confirm', { verificationCode }),
  getWithdrawalDetail: (id: number) =>
    axios.get<WithdrawalType, WithdrawalType>(`/wallet/v1/withdrawals/${id}`),
};

export default earningServices;
