import { axios } from '../utils/axios';
import {
  CreateUserSubscriptionParams,
  InPayStatusType,
  PayMethodType,
  PlanItemsType,
  PlanListItemsType,
  UserPlanListItemsType,
} from '@/stores/PlanStore/types';

export interface IPlanListResponse {
  list: PlanListItemsType[];
}

export interface ICreateUserSubscriptionResponse {
  id: number;
  paySubscriptionId: string; // The PayPal-generated ID for the subscription.
  planItem: PlanItemsType;
}

export interface IGetUserPlanListParams {
  limit: number;
  offset: number;
  orders?: string[];
  inPayStatus?: InPayStatusType[];
  payMethod?: PayMethodType;
}

export interface IUserPlanListResponse {
  list: UserPlanListItemsType[];
}

export interface IUpgradePlanParams {
  planId: number;
  payMethod: PayMethodType;
}

const planServices = {
  // 获取计划列表 http://18.163.16.71:8800/v2/order#tag/Subscription/operation/Order_ListPlans
  getPlanList: () => axios.get<IPlanListResponse, IPlanListResponse>('/order/v1/plans'),

  // 新增用户订阅 http://18.163.16.71:8800/v2/order#tag/Subscription/operation/Order_CreateUserSubscription
  createUserSubscription: (params: CreateUserSubscriptionParams) =>
    axios.post<ICreateUserSubscriptionResponse, ICreateUserSubscriptionResponse>(
      '/order/v1/subscription',
      params
    ),

  // 用户订阅列表 http://18.163.16.71:8800/v2/order#tag/Subscription/operation/Order_ListUserSubscriptions
  getUserPlanList: (params: IGetUserPlanListParams) =>
    axios.get<IUserPlanListResponse, IUserPlanListResponse>('/order/v1/subscriptions', { params }),

  // 更新用户支付状态 http://18.163.16.71:8800/v2/order#tag/Subscription/operation/Order_PaidUserSubscription
  updateUserPayStatus: (id: number) => axios.patch(`/order/v1/subscription/${id}:paid`),

  // 升级用户订阅
  upgradeUserSubscription: (params: IUpgradePlanParams) =>
    axios.post('order/v1/subscription:upgrade', params),
};

export default planServices;
