import { axios } from '../utils/axios';
import { TranslationCategory, TranslationItemType } from '@/stores/TranslationStore/types';

export interface ISrtTranslateParams {
  objectCategory: TranslationCategory;
  objectId: number;
  objectKey: string;
  engine: string;
  model: string;
  targetLang: string;
}

export interface IAITranslateParams {
  key: 'KT_LocalFile' | 'KT_YouTube';
  trans: {
    email: string;
    key: string;
    sourceLang: string;
    targetLang: string;
    srtUrl?: string;
  };
  video: {
    uid?: number;
    url: string;
    title?: string;
    intro?: string;
    duration: number;
  };
}

export interface ITranslationListParams {
  limit: number;
  offset: number;
  status?: string;
  start?: string;
  end?: string;
}

export interface ITranslationListResponse {
  count: number;
  list: TranslationItemType[];
}

const translationServices = {
  srtTranslate: (params: ISrtTranslateParams) => axios.post('/order/v1/trans', params),
  aiTranslate: (params: IAITranslateParams) => axios.post('/order/v1/trans/ai', params),
  getTranslationList: (params: ITranslationListParams) =>
    axios.get<ITranslationListResponse, ITranslationListResponse>('/order/v1/trans/list', {
      params,
    }),
};

export default translationServices;
