import { axios } from '../utils/axios';
import { TransactionListParamsType, TransactionListType } from '@/stores/WalletStore/types';

export interface IBalanceResponse {
  balance: number;
  id: number;
}

export interface IFrozenResponse {
  balance: number;
  frozenAdd: number;
  frozenSub: number;
}

export interface IListResponse {
  list: TransactionListType[];
  total: number;
}

export interface IFreeCountResponse {
  total: number;
  used: number;
  free: number;
}

const walletServices = {
  getBalance: () => axios.get<IBalanceResponse, IBalanceResponse>('/coin/v1/balance'),
  getFrozen: () => axios.get<IFrozenResponse, IFrozenResponse>('/coin/v1/balance/frozen'),
  getTransactionList: (params: TransactionListParamsType) =>
    axios.get<IListResponse, IListResponse>('/coin/v1/transaction', { params }),
};

export default walletServices;
