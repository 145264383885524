import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from './en';
import translation_zh from './zh';
import translation_ko from './ko';

const resources = {
  en: {
    translation: translation_en,
  },
  zh: {
    translation: translation_zh,
  },
  ko: {
    translation: translation_ko,
  },
};

// 获取浏览器语言
const getNavigatorLanguage = () => {
  let languageCode = window.navigator.language || '';
  // 判断是否包含横杠
  const dashIndex = languageCode.indexOf('-');

  // 如果存在横杠，提取横杠前的部分作为主要语言代码
  if (dashIndex !== -1) {
    languageCode = languageCode.substring(0, dashIndex);
  }

  // 如果没有横杠，直接返回原始语言代码
  // 处理原始语言代码,如果是中文返回zh,如果是韩文返回ko,其他返回英文en
  return languageCode === 'zh' ? 'zh' : languageCode === 'ko' ? 'ko' : 'en';
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: JSON.parse(localStorage.getItem('LanguageStore')!)?.language || getNavigatorLanguage(),
  interpolation: {
    escapeValue: false,
  },
});

// Add fallback for brackets variables in the translation key (e.g {0})
const replaceWithParams = (text: string, params: any[]) => {
  let result = text;
  params.forEach((value, index) => {
    const reg = new RegExp(`({)${index}(})`, 'g');
    result = result.replace(reg, value);
  });
  return result;
};

export function tf(key: string, ...params: any[]) {
  return replaceWithParams(i18n.t(key), params);
}

export default i18n;
